import { createContext, useContext, useState } from 'react'

const StateContext = createContext({
  currentUser: null,
  token: null,
  notification: null,
  setUser: () => {},
  setToken: () => {},
  setNotification: () => {},
})

export const ContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN'))
  const [notification, _setNotification] = useState(null)

  const setNotification = (notification) => {
    _setNotification(notification)
    if (notification) {
      setTimeout(() => {
        _setNotification(null)
      }, 3000)
    }
  }
  // set page title
  document.title = `${user?.name || 'Admin'} - Dashboard`

  // const [token, _setToken] = useState(123);

  const setToken = (token) => {
    _setToken(token)
    if (token) {
      localStorage.setItem('ACCESS_TOKEN', token)
    } else {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  return (
    <StateContext.Provider
      value={{
        user,
        token,
        notification,
        setNotification,
        setUser,
        setToken,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

/**
 * This hook returns the current user and token from the context, as well as two functions to update them.
 * The user is an object with a name property, and the token is a string.
 * The setUser function updates the user object.
 * The setToken function updates the token string and stores it in local storage.
 * If the token is falsy, it is removed from local storage.
 * The hook uses the useContext hook from react to get the context value.
 */
export const useStateContext = () => useContext(StateContext)
